.App {

}


.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}


body {
    font-size: .875rem;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 48px; /* Height of navbar */
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Utilities
 */

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.show {
    display: block !important;
}

.modal {
    background: rgba(0, 0, 0, 0.3);
}

.modal-content {
    padding: 30px;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    opacity: 0.5;
    background-color: #000000;
}


.react-form-builder .react-form-builder-toolbar {
    width: 250px;
    background: #fff;
    margin-top: 0 !important;
}

.index_fullpage__2JorV {
    z-index: auto !important;
}

.btn-agree {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-agree:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}

.btn-agree.focus, .btn-agree:focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-agree.disabled, .btn-agree:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-agree:not(:disabled):not(.disabled).active, .btn-agree:not(:disabled):not(.disabled):active, .show > .btn-agree.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-agree:not(:disabled):not(.disabled).active:focus, .btn-agree:not(:disabled):not(.disabled):active:focus, .show > .btn-agree.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}


.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.relative-footer {
    position: fixed !important;
}

.hidden {
    display: none;
}

.auto-scroll .dropdown-menu {
    height: 300px;
    overflow-y: scroll;
}

.auto-scroll.dropdown-menu {
    height: 200px;
    overflow-y: scroll;
}


.full-width {
    width: auto;
}

.btn-secondary.excel-button {
    background-color: #008000;
    border-color: #008000;
}


.center-text-align {
    text-align: center;
}

.add-user, .add-user:hover {
    color: #FFF;
}

.logo-lg img {
    height: 100%;
    width: 100%;
    max-width: 150px;
}

.login-logo, .help-box img {
    width: 100%;
    height: 100%;
}

.dashboard {
    width: 100%;
    height: 100%;
}